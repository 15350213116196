#homepage-animation {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.website-name {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    font-size: 1.8em;
}