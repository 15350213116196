#footer {  
  color: white;
  background-color: whitesmoke;

  .social-icons a svg {
    fill: black;  
  }

  .social-icons a svg:hover {
    fill: gray;
  }

  .fb-link {
    margin-left: 20px;
    margin-right: 20px;  
  }

  #copyright-footer {
    margin-top: 20px;
    font-size: 0.8em;
    color: black;
  }
}





